<template>
  <div class="list-info">
    <top-bar :title="'报修'" :left="true"></top-bar>
    <div class="search">
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入报修人姓名" class="search-content"
             @keyup.enter="onSearch">
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
      <div class="search-btn" @click="onSearch">搜索</div>
    </div>
    <!-- 筛选栏 -->
    <div class="filterBar">
      <van-row>
        <van-col span="8">
          <p @click="dateBarShow = !dateBarShow" :style="{color:dateBarShow?'#387FF5':'#666666'}">报修时间
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt=""
                 class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="typeShow = !typeShow" :style="{color:typeShow?'#387FF5':'#666666'}">报修类型
            <img :src="require(`@/assets/img/${typeShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="8">
          <p @click="statusShow = !statusShow" :style="{color:statusShow?'#387FF5':'#666666'}">处理状态
            <img :src="require(`@/assets/img/${statusShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow">
          <span>{{ dataForm.beginDate == '' ? '最早' : dataForm.beginDate }}</span></van-col>
        <van-col span="2"><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow">
          <span>{{ dataForm.endDate == '' ? '至今' : dataForm.endDate }}</span></van-col>
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="">
    </div>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" type="date" title="选择年月日"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date()"/>
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="date" title="选择年月日"
                           :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)"/>
    </van-popup>
    <van-popup v-model="typeShow" position="bottom">
      <van-picker title="报修类型" show-toolbar :columns="typeList" value-key="label" @confirm="typeConfirm"
                  @cancel="typeShow = false"/>
    </van-popup>
    <van-popup v-model="statusShow" position="bottom">
      <van-picker title="处理状态" show-toolbar :columns="statusList" value-key="label" @confirm="statusConfirm"
                  @cancel="statusShow = false"/>
    </van-popup>

    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                  v-if="dataList.length > 0" offset="10">
          <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div>
          <div class="list-item" v-for="item in dataList" :key="item.id" @click="getInfo(item.id, item.solveStatus)">
            <div class="title">
              <div class="title-left">
                {{ item.userName + ' | ' + item.createTime }}
              </div>
              <div class="title-right" :style="{color:item.solveStatus==0||item.solveStatus==10?'#ED4747':item.solveStatus==20?'#23D87B'
                :item.solveStatus==30?'#387FF5':'#0AB9B3'}">
                {{ item.solveStatusStr }}
              </div>
            </div>
            <div class="content">
              <van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
                <template slot="error">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
                <template slot="loading">
                  <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                </template>
              </van-image>
              <div class="content-text">
                <div class="top-text">{{ item.fullLocation }} <span
                    style="color:#387FF5;font-size:14px">({{ item.repairTypeStr }})</span></div>
                <div class="bottom-text">{{ item.repairDes }}</div>
              </div>
              <van-icon name="arrow" color="#666" class="content-icon"/>
            </div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate} from '@/utils/utils'
import {getImageStream} from '@/utils/index'
import {mapMutations, mapState} from 'vuex'

export default {
  components: {
    topBar
  },
  data() {
    return {
      pullLoading: false,
      loading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      typeShow: false,
      statusShow: false,
      totalCount: 0,
      page: 0,
      limit: 10,
      searchValue: '',
      dataForm: {
        endDate: '',
        beginDate: '',
        type: '',
        status: ''
      },
      typeList: [],
      statusList: [
        {label: '无法处理', value: '0',},
        {label: '未处理', value: '10'},
        {label: '处理中', value: '20'},
        {label: '处理完成,待评论', value: '30'},
        {label: '评论结束', value: '40'},
        {label: '结束', value: '50'}],
      dataList: []
    };
  },
  methods: {
    ...mapMutations,
    // 点击列表跳转到详情页
    getInfo(id, status) {
      let data = {'id': id, 'status': status}
      this.$store.commit('setRepairData', data)
      this.$router.push('/repair-info')
    },
    // 获取列表
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/commonApp/repair/info/list'),
        method: 'post',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          name: this.searchValue,
          startDates: this.dataForm.beginDate,
          endDates: this.dataForm.endDate,
          repairType: this.dataForm.type,
          solveStatus: this.dataForm.status,
          orgId: this.$orgId,
          depth: 5,
          userId: this.$userId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          data.page.list.forEach(item => {
            if (item.fileDisplayVOs && item.fileDisplayVOs.length != 0) {
              let file = item.fileDisplayVOs[0]
              let url = file.relativeUrl
              if (url) {
                url = getImageStream(url)
                item["url"] = url
              }
            }
          })
          this.totalCount = data.page.totalCount
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    // 获取报修类型列表
    getProblemType() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          code: 'repairType'
        }, false)
      }).then(({data}) => {
        if (data.code == 0) {
          this.typeList = data.dicts
          this.typeList.unshift({label: '所有', value: ''})
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
    // 开始时间确认
    beginDateConfim(value) {
      let d = new Date(this.dataForm.endDate)
      if (this.dataForm.endDate != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.dataForm.beginDate = formatterDate(value)
      this.beginDateShow = false
    },
    // 结束时间确认选择
    endDateConfim(value) {
      let d = new Date(this.dataForm.beginDate)
      if (this.dataForm.beginDate != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.dataForm.endDate = formatterDate(value)
      this.endDateShow = false
    },
    // 确认修改查询时间,
    changeDate() {
      this.dataList = []
      this.finished = false
      this.page = 0
      this.getDataList()
    },
    // 重置时间
    dateClose() {
      this.dataForm.endDate = ''
      this.dataForm.beginDate = ''
      this.dataList = []
      this.finished = false
      this.page = 0
      this.getDataList()
    },
    // 类型选择确认
    typeConfirm(value, index) {
      this.page = 0
      this.dataList = []
      this.dataForm.type = value.value
      this.getDataList()
      this.finished = false
      this.typeShow = false
    },
    statusConfirm(value, index) {
      this.page = 0
      this.dataList = []
      this.dataForm.status = value.value
      this.getDataList()
      this.finished = false
      this.statusShow = false
    },
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch() {
      this.page = 0
      this.finished = false
      this.dataList = []
      this.getDataList()
    },
    onCancel() {
      this.page = 0
      this.dataList = []
      this.searchValue = ''
      this.getDataList()
    },
    // 去新增页面
    goAdd() {
      this.$router.push('/repair-add')
    }
  },
  created() {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
    this.getProblemType()
  }
}
</script>

<style lang="scss" scoped>
.van-field__control {
  text-align: left !important;
}

.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}

.checkedTag {
  background-color: rgba(0, 122, 255, 0.1);
  color: #387ff5;
  border: 1px solid #387ff5;
}

.commonTag {
  background-color: #f0f2f5;
  color: #333;
}
</style>
